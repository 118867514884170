<template>

	  <div>
	  	
	  	Gantt 3
	  </div>


</template>



<script type="text/javascript">
	
</script>

<script>
	export default{
		name:'gantt3',
		props:{
			imgsrc:String,
			variant:String,
			name:String,
			silent:{
				type: Boolean,
				default: false
			},
			noimage:{
				type: Boolean,
				default: false
			},
			width:Number,
			height:Number,
			newclass:String,
			newid:String
		},

		watch:{
			
		},
		mounted(){

		},
		computed:{
		}
	}
	//  
</script>